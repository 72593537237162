.ll-nam, .ll-num { /* words and numbers */
    @apply text-gray-100;
}

.ll-str { /* strings */
    color: rgb(173, 219, 103);
}

.ll-rex { /* regular expressions */
    color: #ef6c00;
}

.ll-pct { /* operators, punctation */
    @apply text-teal-200;
}

.ll-key { /* keywords */
    @apply text-orange-300;
    @apply font-normal;
}

.ll-com { /* comments */
    color: #aaa;
    font-style: italic;
}
