/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

body {
    @apply bg-gray-800;
    font-size: 13px;
    height: 100vh;
    padding: 1rem;
    font-family: 'Fira Code Retina', 'Fira Code', monospace;
}

#root {
    height: 100%;
}

a, a:link {
    @apply font-bold;
    @apply text-blue-600;
}
